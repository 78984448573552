.ProseMirror {
    @apply p-2;
}

.ProseMirror:focus {
    outline: none;
}

.ProseMirror img {
    max-width: 100%;
    max-height: 100%;
}

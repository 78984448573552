@import 'tailwindcss/base';
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "codemirror/lib/codemirror";
@import "codemirror/theme/tomorrow-night-eighties";

@import './vendor/prosemirror/menu.css';
@import './vendor/prosemirror/prosemirror.css';
@import './vendor/prosemirror/style.css';

body {
    overscroll-behavior: none;
}

.CodeMirror {
    @apply h-auto rounded-md py-2 my-2 -mx-1;
}
